<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                Mitarbeitergruppen
            </div>
            <div class="card-tools">
                <button class="btn btn-primary button-sm" @click="openModal('createModal')" v-if="$auth.check('employee.groups.create')">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-hover table-sm">
                <thead>
                    <tr>
                        <th>Gruppe</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="group in employeeGroups.data" :key="group.id">
                        <td>{{group.name}}</td>
                        <td>
                            <button class="btn btn-warning btn-sm mr-1" @click="openModal('editModal', group)" v-if="$auth.check('employee.groups.edit')">
                                <i class="fas fa-edit"></i>
                            </button>
                            <button class="btn btn-danger btn-sm" @click="deleteEmployeeGroup(group.id)" v-if="$auth.check('employee.groups.destroy')">
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">

        </div>
        <b-modal
            id="createModal"
            title="Gruppe erstellen"
            @ok.prevent="handleOk"
            size="md"
            :ok-title="'Bestätigen'"
            :cancel-title="'Abbrechen'"
            @shown="form.reset || form.errors.clear()"
        >
            <label>Name*</label>
            <input type="text" class="form-control form-control-sm" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}">
            <has-error :form="form" field="name"></has-error>
        </b-modal>
        <b-modal
            id="editModal"
            title="Gruppe bearbeiten"
            @ok.prevent="handleOkEdit"
            size="md"
            :ok-title="'Bestätigen'"
            :cancel-title="'Abbrechen'"
            @shown="form.reset || form.errors.clear()"
        >
            <label>Name*</label>
            <input type="text" class="form-control form-control-sm" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}">
            <has-error :form="form" field="name"></has-error>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "EmployeeGroupIndex",
    title: "Mitarbeitergruppen verwaltung",
    data() {
        return {
            employeeGroups: {},
            form: new window.Form({
                id: null,
                name: "",
            })
        }
    },
    methods: {
        getEmployeeGroups()
        {
            this.axios
                .get("/employee/groups")
                .then((response) => {
                    this.employeeGroups = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: "Oops...",
                        text: "Da ist wohl etwas schief gelaufen"
                    })
                })
        },
        handleOk(evt) {
            evt.preventDefault();
            this.form
                .post("/employee/groups")
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        showConfirmButton: false,
                        title: "Gruppe wurde erstellt."
                    });
                    this.closeModal("createModal");
                    this.getEmployeeGroups();
                    this.form.reset();
                })
                .catch(() => {
                })
        },
        handleOkEdit(evt)
        {
            evt.preventDefault();
            this.form
                .put("/employee/groups/" + this.form.id)
                .then(() => {
                    this.getEmployeeGroups();
                    this.closeModal('editModal');
                    this.form.reset();
                })
                .catch(() => {

                });
        },
        deleteEmployeeGroup(id)
        {
            this.axios
                .delete("/employee/groups/" + id)
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        showConfirmButton: false,
                        title: "Gruppe wurde gelöscht."
                    });
                    this.getEmployeeGroups();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        openModal(name, group = null)
        {
            if(group != null)
            {
                this.form.id = group.id;
                this.form.name = group.name;
            }
            this.$bvModal.show(name);
        },
        closeModal(name)
        {
            this.$bvModal.hide(name);
        }
    },
    mounted() {
        this.getEmployeeGroups();
    }
}
</script>

<style>

</style>